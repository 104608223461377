import { configureStore } from '@reduxjs/toolkit';
import { api } from './utils/api';
import authReducer from './features/Auth/hooks/authSlice';
import globalLoaderReducer from './features/GlobalLoader/globalLoaderSlice';
import siteReducer from './features/Site/SiteSlice';
import { queryErrorLogger } from './utils/queryErrorLogger';
import dashboardReducer from './features/Dashboard/dashboardSlice';
import popupReducer from './features/PopUp/popupSlice';
import maintenanceModeReducer from './features/MaintenanceMode/api/MaintenanceModeSlice';
// Import reducers here

const store = configureStore({
    reducer: {
        // Add reducers here
        [api.reducerPath]: api.reducer,
        'auth': authReducer,
        'globalLoading': globalLoaderReducer,
        'site': siteReducer,
        'dashboard': dashboardReducer,
        'popup': popupReducer,
        'maintenanceMode': maintenanceModeReducer,
    },
    // middlewares
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware, queryErrorLogger),
});

export default store;