import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { updateAccess, logout } from '../features/Auth/hooks/authSlice';
import { setMaintenanceMode } from '../features/MaintenanceMode/api/MaintenanceModeSlice'; // Import maintenance mode action
import { LgbtToast } from '../components/LgbtToast/LgbtToast';


const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL,
})

// Define a function to extract query parameters from the URL
const getQueryDomainParam = () => {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);

    return url.searchParams.get('site');
};

// Define a function to inject headers and tokens into requests
const customBaseQueryWithReauth = async (args, api, extraOptions) => {

    const domain = getQueryDomainParam();

    let apiWithDomain = args;
    if (domain !== null) {
        apiWithDomain += `?site=${encodeURIComponent(domain)}`;
    }

    let result = await baseQuery(apiWithDomain, api, extraOptions);

    if (result.error && result.error.status === 503) {
        api.dispatch(setMaintenanceMode(true));
        return result;
    }

    if (result.error && result.error.status === 401) {

        // If the request is unauthorized, try to refresh the token before retrying the request
        try {
            // Your custom logic to make the POST request
            const response = await fetch(
                process.env.REACT_APP_API_BASE_URL + '/user/token/refresh/',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        'refresh': api.getState().auth.refreshToken
                    }), // Pass your request body here
                    signal: api.signal, // Optional: for cancellation
                }
            );
            if (!response.ok) {
                throw new Error('Request failed');
            }

            const data = await response.json();
            if (data?.access) {
                await api.dispatch(updateAccess({ access: data.access }))
                args = {
                    ...args,
                    headers: {
                        ...args.headers,
                        Authorization: `Bearer ${data.access}`,
                    }
                }
                result = await baseQuery(args, api, extraOptions)
            } else {
                LgbtToast({ message: 'Please Login', type: 'warn' })
                api.dispatch(logout());
            }

        } catch (error) {
            // TODO: Show toast once refresh token failed.
            // LgbtToast({message: 'Please login again', type: 'warn'})

            api.dispatch(logout());

            // Convert the error to a plain object or include relevant details
            const errorObject = {
                message: error.message, // include the error message
                name: error.name, // include other relevant properties
            };
            return { error: errorObject };
        }
    }
    return result;
}


// Define a service using a base URL and expected endpoints
export const api = createApi({
    baseQuery: customBaseQueryWithReauth,
    mode: "cors",
    endpoints: () => ({}),
});
