import { api } from '../../../utils/api';

const siteApiSlice = api.injectEndpoints({
    endpoints: (builder) => ({
        getSiteSettings: builder.query({
            query: (domain = '') => {
                return `/multisitecontrolpanel/site-settings/`
            },
            transformResponse: (response, meta) => {
                if (meta?.response?.status === 503) {
                    throw { status: 503, data: response };
                }
                if (response?.MAINTENANCE_MODE === true) {
                    throw { status: 503, data: response };
                }
                return response;
            },
            transformErrorResponse: (response) => {
                return {
                    status: response.status,
                    data: response.data
                };
            }
        }),
    }),
});

export const { useGetSiteSettingsQuery } = siteApiSlice;