import React from 'react';
import { useSelector } from 'react-redux';
import { FaExclamationTriangle } from 'react-icons/fa';
import styles from './styles/MaintenanceMode.module.scss';

const MaintenanceMode = () => {
    const isMaintenance = useSelector((state) => state.maintenanceMode.isMaintenance);

    if (!isMaintenance) return null;

    return (
        <div className={styles["maintenance-container"]}>
            <FaExclamationTriangle className={styles.icon} />
            <h1>We'll be back soon!</h1>
            <p>
                Sorry for the inconvenience. We're performing some maintenance at the moment. 
            </p>
        </div>
    );
};

export default MaintenanceMode;