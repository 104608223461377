import React from 'react'
import { AiOutlineClose, AiFillFilePdf, AiFillFileWord, AiFillFileText } from 'react-icons/ai';
import { FaRegEdit } from 'react-icons/fa';
import styles from './DocumentPreview.module.scss'
import { useState } from 'react';
import { useRenameDocumentMutation, useRemoveDocumentMutation } from '../../api/jobCreateApiSlice'
import DotLineLoader from '../../../../components/Loader/DotLineLoader/DotLineLoader';

const getFileIcon = (fileType) => {
    if (fileType === 'application/pdf') {
        return <AiFillFilePdf className={styles.fileIcon} />;
    } else if (fileType === 'application/msword' || fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        return <AiFillFileWord className={styles.fileIcon} />;
    } else {
        return <AiFillFileText className={styles.fileIcon} />;
    }
};


function DocumentPreview({ index, file, onUpdate, onRemove, loading }) {

    const [tempFileName, setTempFileName] = useState('');
    const [isEditing, setIsEditing] = useState(false)
    const [isLoading, setIsLoading] = useState(false);

    const [
        renameDocument,
        {
            isFetching: isRenameFetching,
            isLoading: isRenameLoading,
            isError: isRenameError,
            error: renameError
        }
    ] = useRenameDocumentMutation();

    const [
        deleteDocument, {
            isFetching: isDeleteFetching,
            isLoading: isDeleteLoading,
            isError: isDeleteError,
            error: deleteError
        }
    ] = useRemoveDocumentMutation();

    const handleBlur = async () => {
        setIsLoading(true)
        setIsEditing(false)
        const updatedFile = await renameDocument({ id: file.id, name: tempFileName })
        onUpdate(index, updatedFile.data)
        setIsLoading(false)
    }

    const handleEditClick = () => {
        const fileNameWithoutExt = file.name.split('.').slice(0, -1).join('.');
        setTempFileName(fileNameWithoutExt);
        setIsEditing(true)
    }

    const handleRemove = async () => {
        setIsLoading(true)
        onRemove(index)
        setIsLoading(false)
    }


    return (
        <div className={styles.fileswrapper}>
            <div className={styles.fileItem}>
                {
                    isLoading ? <DotLineLoader />
                        :
                        <div className={styles.contentBody}>
                            {getFileIcon(file.type)}
                            {isEditing ? (
                                <input
                                    type="text"
                                    className={styles.fileInput}
                                    value={tempFileName}
                                    onChange={(e) => setTempFileName(e.target.value)}
                                    onBlur={handleBlur}
                                    autoFocus
                                />
                            ) : (
                                <span className={styles.fileName}>{file.name}</span>
                            )}
                            {!isEditing ? (
                                <FaRegEdit
                                    className={styles.fileEditIcon}
                                    onClick={() => handleEditClick()}
                                />
                            ) : null}
                            <div className={styles.close}>
                                <AiOutlineClose
                                    className={styles.removeIcon}
                                    onClick={() => handleRemove(index)}
                                />
                            </div>
                        </div>
                }
            </div>
        </div>
    )
}

export default DocumentPreview