import { api } from '../../../../../utils/api';

const LogosApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getLogos: builder.query({
            query: ({ page, page_size }) => {
                return {
                    url: `/jobmanager/our-clients/`,
                    params: { page, page_size },
                };
            },
        }),
    }),
});

export const { useGetLogosQuery } = LogosApi;
