import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isMaintenance: false,
};

const maintenanceModeSlice = createSlice({
    name: 'maintenanceMode',
    initialState,
    reducers: {
        setMaintenanceMode: (state, action) => {
            state.isMaintenance = action.payload;
        },
    },
});

export const { setMaintenanceMode } = maintenanceModeSlice.actions;
export default maintenanceModeSlice.reducer;