import React, { useState, useEffect, useRef, useCallback } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import ReactGA from 'react-ga4';
import { useDispatch, useSelector } from 'react-redux';
import { useGetSiteSettingsQuery } from './features/Site/api/siteApiSlice';
import { setMaintenanceMode } from './features/MaintenanceMode/api/MaintenanceModeSlice';

import useAppInitializer from './common/hooks/useAppInitializer';

import GAPageTracker from './components/GAPageTracker/GAPageTracker';
import ZoomerToolbar from './components/ZoomerToolbar/ZoomerToolbar';
import HomePage from './pages/HomePage';
import JobPostPage from './pages/JobPostPage';
import ContactPage from './pages/ContactPage';
import SignUpPage from './pages/SignUpPage';
import EmployerDirectory from './pages/EmployerDirectory'
import Layout from './components/Layout/Layout';
import GlobalLoader from './features/GlobalLoader';
import SearchPage from './pages/JobSearchPage'
import JobSinglePage from './pages/JobSinglePage';
import JobAlertPage from './pages/JobAlertPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import TermsAndConditionsPage from './pages/TermsAndConditionsPage';
import JobSinglePreview from './features/JobSinglePage/components/JobSinglePreview';
import AdminLoginInterceptor from './features/AdminLoginInterceptor';
import Login from './features/Auth/components/Login';
import SignUp from './features/Auth/components/SignUp';
import SignUpConfirm from './features/Auth/components/SignUpConfirm/SignUpConfirm';
import ForgotPassword from './features/Auth/components/ForgotPassword/ForgotPassword';
import ForgotPasswordConfirm from './features/Auth/components/ForgotPasswordConfirm/ForgotPasswordConfirm';
import JobAlertListing from './features/JobSeeker/components/JobAlertListing/JobAlertListing.jsx';
import JobAlertUpdateForm from './features/JobAlert/Components/JobAlertUpdateForm';
import ProfileUpdate from './features/JobSeeker/components/ProfileUpdate/ProfileUpdate';
import AboutPage from './pages/AboutPage';
import EmployerProfile from './features/EmployerSingle';
import ExpiredJobs from './features/ExpiredJobs';
import DashBoard from './features/Dashboard';
import NotFound from './components/NotFound/NotFound';
import Unauthorized from './components/Unauthorized/Unauthorized';
import JobPackages from './features/Dashboard/components/JobPackages/JobPackages';
import JobSeekerDashBoard from './features/JobSeeker/'
import MaintenanceMode from './features/MaintenanceMode/MaintenanceMode.jsx';

import RequireAuth from './features/Auth/components/ProtectedRoute';
import NoData from './components/NoData/NoData';
import ScrollRestorer from './components/ScrollRestorer/ScrollRestorer';

import './assets/styles/main.scss'
import 'react-toastify/dist/ReactToastify.css';
import MyJobs from './features/Dashboard/components/MyJobs/MyJobs';
import ResetPassword from './features/Dashboard/components/ResetPassword/ResetPassword';
import TextToSpeech from './components/TextToSpeech/TextToSpeech'
import MicAccessTool from './common/functions/acctoolbar.js';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import SignUpVerification from './features/Auth/components/SignUpVerification/SignUpVerification.jsx';
import LoginConfirm from './features/Auth/components/LogInConfirm/LogInConfirm.jsx';
import BannerPopUp from './components/BannerPopUp/BannerPopUp.jsx';

const App = () => {
  const [isAuthInitialized, setAuthInitialized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  
  const dispatch = useDispatch();
  const isMaintenance = useSelector((state) => state.maintenanceMode.isMaintenance);
  const siteConfig = useSelector(state => state.site);

  const appInitializer = useAppInitializer();
  const memoizedAppInitializer = useCallback(() => appInitializer(), [appInitializer]);
  const appRef = useRef();

  const {
    data: siteSettings,
    error: siteError,
  } = useGetSiteSettingsQuery();

  let siteStyles = {
    '--primary-color': siteConfig.primaryColor,
    '--primary-transparent': siteConfig.primaryColor + '44',
    '--secondary-color': siteConfig.secondaryColor,
    '--complementary-color': siteConfig.complementaryColor,
    '--decor-color-1': siteConfig.decorColor1,
    '--decor-color-2': siteConfig.decorColor2,
    '--decor-color-3': siteConfig.decorColor3,
    '--decor-color-4': siteConfig.decorColor4,
    '--decor-color-5': siteConfig.decorColor5,
    '--decor-color-6': siteConfig.decorColor6,
    '--decor-color-7': siteConfig.decorColor7,
    '--decor-color-8': siteConfig.decorColor8,
    overflowY: 'hidden',
    position: 'relative',
  };

  useEffect(() => {
    const initializeApp = async () => {
      if (!appRef.current) {
        memoizedAppInitializer();
        setAuthInitialized(true);
      }
      appRef.current = true;
    };
    initializeApp();
  }, [memoizedAppInitializer]);

  useEffect(() => {
    window.isWindowLoaded = false;
    const handleLoad = () => {
      window.isWindowLoaded = true;
    };
    window.addEventListener('load', handleLoad);
    return () => {
      window.removeEventListener('load', handleLoad);
    };
  }, []);

  useEffect(() => {
    const hasAcceptedCookies = localStorage.getItem('cookiesAccepted');
    const measurementID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;

    if (measurementID && hasAcceptedCookies === 'true') {
      ReactGA.initialize(measurementID);
    } else if (measurementID) {
      console.warn('User has not accepted cookies for tracking');
    } else {
      console.warn('Add measurement ID for tracking');
    }
  }, []);

  useEffect(() => {
    const isMaintenanceMode = 
      siteError?.status === 503 || 
      siteSettings?.MAINTENANCE_MODE === true;

    dispatch(setMaintenanceMode(isMaintenanceMode));
    setIsLoading(false);
  }, [siteError, siteSettings, dispatch]);

  const isAccessToolbarEnabled = siteConfig?.enableAccessToolbar;
  const isZoomerToolBarEnabled = siteConfig?.enableZoomToolbar;
  const isTextToSpeechEnabled = siteConfig?.enableTextToSpeech;

  const renderMicAccessTool = () => {
    window.micAccessTool = new MicAccessTool();
    return null;
  };

  if (!isAuthInitialized || isLoading) {
    return <div>Loading...</div>;
  }

  if (isMaintenance) {
    return <MaintenanceMode />;
  }

  return (
    <div className='app' style={siteStyles}>
      <GlobalLoader />
      <BrowserRouter>
        <BannerPopUp />
        <GAPageTracker />
        <ScrollRestorer />
        {isTextToSpeechEnabled === true ? <TextToSpeech /> : null}
        <Layout layoutStyles={siteConfig}>
          {isAccessToolbarEnabled === true ? renderMicAccessTool() : null}
          {isZoomerToolBarEnabled === true ? <ZoomerToolbar /> : null}
          <Routes>
            <Route path='/' element={<HomePage />} />
            <Route path='/post-a-job' element={<RequireAuth allowedRoles={['recruiter']}><JobPostPage /></RequireAuth>} />
            <Route path='/job-alert' element={<GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}><JobAlertPage /></GoogleReCaptchaProvider>} />
            <Route path='/privacy-policy' element={<PrivacyPolicyPage />} />
            <Route path='/tos' element={<TermsAndConditionsPage />} />
            <Route path='/inclusive-hiring-employers-directory' element={<EmployerDirectory />} />
            <Route path='/contact-us' element={<ContactPage />} />
            <Route path='/about-us' element={<AboutPage />} />
            <Route path='/sign-up' element={<SignUpPage />} />
            <Route path='/signup-confirm' element={<SignUpConfirm />} />
            <Route path="/find-a-job" element={<SearchPage />} />
            <Route path="/admin-login-interceptor" element={<AdminLoginInterceptor />} />
            <Route path="/job/:jobSlug" element={<JobSinglePage />} />
            <Route path="/job/preview" element={<JobSinglePreview />} />
            <Route path="/job/:jobId/edit" element={<RequireAuth allowedRoles={['recruiter']}><JobPostPage /></RequireAuth>} />
            <Route path="/job/:jobId/duplicate" element={<RequireAuth allowedRoles={['recruiter']}><JobPostPage type="duplicate" /></RequireAuth>} />
            <Route path="/job-alert/:jobAlertId/edit" element={<RequireAuth allowedRoles={['jobseeker', 'recruiter']}><JobAlertUpdateForm /></RequireAuth>} />
            <Route path="/employer/:employerSlug" element={<EmployerProfile />} />
            <Route path="/login" element={<Login />} />
            <Route path="/jobseeker-signup" element={<SignUp />} />
            <Route path="/jobseeker-signup-verification" element={<SignUpVerification />} />
            <Route path="/jobseeker-login-verification" element={<LoginConfirm />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/password-update" element={<ForgotPasswordConfirm />} />
            <Route path="/expired-jobs" element={<ExpiredJobs />} />
            <Route path="/dashboard" element={<RequireAuth allowedRoles={['recruiter']}><DashBoard /></RequireAuth>}>
              <Route index element={<MyJobs />} />
              <Route path='my-jobs' element={<MyJobs />} />
              <Route path='my-packages' element={<JobPackages />} />
              <Route path='reset-password' element={<ResetPassword />} />
            </Route>
            <Route path="/my-account" element={<RequireAuth allowedRoles={['jobseeker']}><JobSeekerDashBoard /></RequireAuth>}>
              <Route index element={<JobAlertListing />} />
              <Route path='job-alerts' element={<JobAlertListing />} />
              <Route path='reset-password' element={<ResetPassword />} />
              <Route path='edit-profile' element={<ProfileUpdate />} />
            </Route>
            <Route path="/unauthorized" element={<Unauthorized />} />
            <Route path="*" element={<NoData><NotFound /></NoData>} />
          </Routes>
        </Layout>
      </BrowserRouter>
      <ToastContainer />
    </div>
  );
};

export default React.memo(App);