import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import styles from './EmployerGrid.module.scss';
import useCompanyUrl from '../../../../common/hooks/useCompanyUrl';
import { useGetLogosQuery } from './api/LogoSearchSlice';

function EmployerGrid(props) {
    const page_size = 20;
    const [page, setPage] = useState(1);
    const [logos, setLogos] = useState([]);
    const [visibleItems, setVisibleItems] = useState([]);
    const [hasMore, setHasMore] = useState(true);

    const companyUrl = useCompanyUrl();
    const { data, isLoading } = useGetLogosQuery({ page, page_size });

    const observer = useRef();

    // Update logos and handle pagination
    useEffect(() => {
        if (data?.results?.length) {
            setLogos((prevLogos) => [...prevLogos, ...data.results]);
            if (data.results.length < page_size) {
                setHasMore(false);
            }
        }
    }, [data]);

    // Intersection Observer for Infinite Scroll
    const lastItemRef = useCallback(
        (node) => {
            if (isLoading || !hasMore) return;

            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver(
                (entries) => {
                    if (entries[0].isIntersecting) {
                        setPage((prevPage) => prevPage + 1);
                    }
                },
                { threshold: 1.0 }
            );
            if (node) observer.current.observe(node);
        },
        [isLoading, hasMore]
    );

    // Resize Effect to Control Visible Items
    useEffect(() => {
        function handleResize() {
            if (!logos.length) return;

            const screenWidth = window.innerWidth;
            let itemsPerRow;

            if (screenWidth > 1400) {
                itemsPerRow = 5;
            } else if (screenWidth > 1200) {
                itemsPerRow = 4;
            } else if (screenWidth > 768) {
                itemsPerRow = 3;
            } else {
                itemsPerRow = 2;
            }

            const length = logos.length;
            const visible = Math.min(length, Math.floor(length / itemsPerRow) * itemsPerRow);
            setVisibleItems(logos.slice(0, visible));
        }

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [logos]);

    if (!logos.length && isLoading) return <div>Loading...</div>;
    if (!logos.length) return <div>No logos found.</div>;

    return (
        <div key={props.index} className={styles.clientListing}>
            <div className="container">
                <h2 style={props.data?.title_color ? { color: props.data.title_color } : null}>
                    {props.data?.title}
                </h2>
                <ul className={styles.listingWrapper}>
                    {visibleItems.map((company, index) => (
                        <li
                            key={index}
                            className={styles.logoContainer}
                            ref={index === visibleItems.length - 1 ? lastItemRef : null}
                        >
                            <Link to={company ? companyUrl(company.slug) : '#'}>
                                <div className={styles.logoWrapper}>
                                    <img
                                        src={company.logo_url}
                                        className="img-fluid"
                                        alt={`${company.name} Logo`}
                                    />
                                </div>
                            </Link>
                        </li>
                    ))}
                </ul>
                {isLoading && <div>Loading more...</div>}
            </div>
        </div>
    );
}

export default EmployerGrid;
